<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div class="my-3">
    <div class="d-flex flex-row align-center justify-space-between mb-4">
      <div class="top-header mb-0 font-weight-bold">
        {{ "Import Tasks" }}
      </div>
    </div>
    <v-tabs v-model="tab" centered icons-and-text>
      <v-tabs-slider></v-tabs-slider>
      <v-tab href="#policies"
        >Policies <v-icon>mdi-clipboard-text</v-icon></v-tab
      >
      <v-tab href="#payments">Payments<v-icon>mdi-cash-usd</v-icon></v-tab>
      <v-tab href="#delincuents">Delinquents<v-icon>mdi-account</v-icon></v-tab>
      <v-tab href="#chargeback">Chargeback<v-icon>mdi-account</v-icon></v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item value="policies">
        <v-card flat> <import-policy /> </v-card
      ></v-tab-item>
      <v-tab-item value="payments"
        ><v-card flat>
          <import-payment />
        </v-card>
      </v-tab-item>
      <v-tab-item value="delincuents"
        ><v-card flat>
          <import-delincuent />
        </v-card>
      </v-tab-item>
      <v-tab-item value="chargeback"
        ><v-card flat>
          <import-chargeback />
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>
<script>
import ImportPolicy from "./ImportPolicy.vue";
import ImportPayment from "./ImportPayment.vue";
import ImportDelincuent from "./ImportDelincuent.vue";
import ImportChargeback from "./ImportChargeback.vue";
export default {
  components: {
    ImportPolicy,
    ImportPayment,
    ImportDelincuent,
    ImportChargeback,
  },
  name: "import-policy-payments",
  data() {
    return {
      tab: null,
    };
  },
};
</script>
<style lang=""></style>
