const months = [
  {
    abbreviation: "Jan",
    name: "January",
    index: 1,
    value: "JANUARY",
  },
  {
    abbreviation: "Feb",
    name: "February",
    index: 2,
    value: "FEBRUARY",
  },
  {
    abbreviation: "Mar",
    name: "March",
    index: 3,
    value: "MARCH",
  },
  {
    abbreviation: "Apr",
    name: "April",
    index: 4,
    value: "APRIL",
  },
  {
    abbreviation: "May",
    name: "May",
    index: 5,
    value: "MAY",
  },
  {
    abbreviation: "Jun",
    name: "June",
    index: 6,
    value: "JUNE",
  },
  {
    abbreviation: "Jul",
    name: "July",
    index: 7,
    value: "JULY",
  },
  {
    abbreviation: "Aug",
    name: "August",
    index: 8,
    value: "AUGUST",
  },
  {
    abbreviation: "Sep",
    name: "September",
    index: 9,
    value: "SEPTEMBER",
  },
  {
    abbreviation: "Oct",
    name: "October",
    index: 10,
    value: "OCTOBER",
  },
  {
    abbreviation: "Nov",
    name: "November",
    index: 11,
    value: "NOVEMBER",
  },
  {
    abbreviation: "Dec",
    name: "December",
    index: 12,
    value: "DECEMBER",
  },
];

export default months;
